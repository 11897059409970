import React,{useState}from 'react'
import { authentications } from "../Data/authentication"

function Login(props) {
    const [email,setEmail]=useState("")
    const [password, setPassword]=useState("")
    const [error, setError]=useState("")

    const handleLoginClick=(event) =>{
        event.preventDefault()
        authentications.forEach(item=>{
            if(item.email===email){
                if(item.password === password){
                    props.handleLogin()
                }else{
                    return
                }
            }
        })
        setError("Wrong credentials")
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="w-full max-w-md">
                <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <h2 className="text-gray-800 font-bold text-4xl mb-2">Login</h2>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email" type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)}/>
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password" type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                    </div>
                    <h2 className="text-red-500 font-bold text-2xl mb-2">{error}</h2>

                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleLoginClick}>
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
