export const sap_fi_cloud=[
    {"chapter_ID":1,
    "question_ID":1,
    "question_text":"Where do you activate SAP Best Practice processes in your quality system - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP S/4HANA Cloud Manage Your Solution app"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Central Business Configuration"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Best Practices Explorer"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Solution Builder"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":2,
    "question_text":"On which level do you maintain the long text for a general ledger account? (X –certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Financial statement version"},
        {"answer_ID":"B","selected":false,"answer_text":"Company code"},
        {"answer_ID":"C","selected":false,"answer_text":"Chart of accounts"},
        {"answer_ID":"D","selected":false,"answer_text":"Controlling area"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":3,
    "question_text":"The credit profile is used to store which information in the customer master data? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The customer credit group"},
        {"answer_ID":"B","selected":false,"answer_text":"The scoring rules"},
        {"answer_ID":"C","selected":false,"answer_text":"The credit exposure"},
        {"answer_ID":"D","selected":false,"answer_text":"The credit decisions"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":4,
    "question_text":"What are maintenance functions of the financial statement version (FSV) with the “Manage Global Hierarchies” app? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Create G/L account master data"},
        {"answer_ID":"B","selected":false,"answer_text":"Create draft hierarchy versions"},
        {"answer_ID":"C","selected":false,"answer_text":"Assign profit centers"},
        {"answer_ID":"D","selected":false,"answer_text":"Assign semantic objects"},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":5,
    "question_text":"You reviewed the project scope and identified the corresponding resources. Based on the SAP Activate methodology, which activities must you perform in the Explore phase? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Use the Quality system to demonstrate SAP Best Practices processes."},
        {"answer_ID":"B","selected":false,"answer_text":"Conduct the Digital Discovery Assessment to identify fits and gaps."},
        {"answer_ID":"C","selected":false,"answer_text":"Define the organizational structure and chart of accounts"},
        {"answer_ID":"D","selected":false,"answer_text":"Conduct the Fit-to-Standard analysis to identify fits and gaps."},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":6,
    "question_text":"As a pre-closing activity, selected suppliers are to confirm their balances. Which confirmation procedure do you use when a response is expected 'only' in case of discrepancies? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Account statement"},
        {"answer_ID":"B","selected":false,"answer_text":"Balance confirmation"},
        {"answer_ID":"C","selected":false,"answer_text":"Balance request"},
        {"answer_ID":"D","selected":false,"answer_text":"Balance notification"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":7,
    "question_text":"What does SAP provide to customers to accelerate the implementation process for S/4HANA cloud? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Activate"},
        {"answer_ID":"B","selected":false,"answer_text":"ASAP"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Leonardo"},
        {"answer_ID":"D","selected":false,"answer_text":"Repaid Application Development"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":8,
    "question_text":"What is the purpose of the Test Automation Tool? - Choose one:"	,
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Provides information on the business app(s) to be used for testing."},
        {"answer_ID":"B","selected":false,"answer_text":"Populates business data for each test screen."},
        {"answer_ID":"C","selected":false,"answer_text":"Provides information on your actual tenant configuration."},
        {"answer_ID":"D","selected":false,"answer_text":"Performs actions in a simulated user interface on behalf of the assigned test user."},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":9,
    "question_text":"What can a business user do with personal settings? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Merge two or more columns into a single column"},
        {"answer_ID":"B","selected":false,"answer_text":"Change the format of dates and numbers"},
        {"answer_ID":"C","selected":false,"answer_text":"Change the order of columns in table"},
        {"answer_ID":"D","selected":false,"answer_text":"Modify the UI layout of the app"},
    ],
    "answer_correct":["C","D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":10,
    "question_text":"Which Key Performance Indicators are available in the Treasury Executive Dashboard in SAP Analytics Cloud? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Payments per Company Code"},
        {"answer_ID":"B","selected":false,"answer_text":"Counterparty Risk – Utilization Overview"},
        {"answer_ID":"C","selected":false,"answer_text":"Open Disputes by Country"},
        {"answer_ID":"D","selected":false,"answer_text":"Liquidity by Region"},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":11,
    "question_text":"When can you add relationships in the organizational structure in SAP Central Business Configuration? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"During content activation"},
        {"answer_ID":"B","selected":false,"answer_text":"Before initial content activation"},
        {"answer_ID":"C","selected":false,"answer_text":"After content activation"},
        {"answer_ID":"D","selected":false,"answer_text":"Before defining the primary finance settings"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":12,
    "question_text":"Which of the following can be transported using Software collection apps in SAP S/4HANA Cloud? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Variants and changes"},
        {"answer_ID":"B","selected":false,"answer_text":"Migration projects"},
        {"answer_ID":"C","selected":false,"answer_text":"Custom business roles"},
        {"answer_ID":"D","selected":false,"answer_text":"Custom business objects"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":13,
    "question_text":"What is the hedging area used for? (X) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"To create and assign the hedged item in a hedging relationship"},
        {"answer_ID":"B","selected":false,"answer_text":"To define the level of granularity when monitoring foreign exchange risk"},
        {"answer_ID":"C","selected":false,"answer_text":"To determine whether the hedging instruments are designated"},
        {"answer_ID":"D","selected":false,"answer_text":"To structure hedging instruments into freely definable categories"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":14,
    "question_text":"To which object is the posting period variant assigned? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Company Code"},
        {"answer_ID":"B","selected":false,"answer_text":"Fiscal year variant"},
        {"answer_ID":"C","selected":false,"answer_text":"Plant"},
        {"answer_ID":"D","selected":false,"answer_text":"Account type"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":15,
    "question_text":"You are implementing the Treasury Trading via Trading Platforms functionality. Which actions can you perform with the Manage Trade Requests App on the SAP Cloud Platform? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Split request"},
        {"answer_ID":"B","selected":false,"answer_text":"Change of originally traded amount"},
        {"answer_ID":"C","selected":false,"answer_text":"Create hedge requests"},
        {"answer_ID":"D","selected":false,"answer_text":"Block requests"},
    ],
    "answer_correct":["A","C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":16,
    "question_text":"Which function in SAP S/4HANA makes it possible to provide segmented financial reporting? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Currency conversion"},
        {"answer_ID":"B","selected":false,"answer_text":"Functional area"},
        {"answer_ID":"C","selected":false,"answer_text":"Ledger"},
        {"answer_ID":"D","selected":false,"answer_text":"Document splitting"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":17,
    "question_text":"What must you create when you build an integration scenario using the SAP Fiori Communication Management apps in SAP S/4HANA Cloud Public Edition? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Communication interface"},
        {"answer_ID":"B","selected":false,"answer_text":"Communication system"},
        {"answer_ID":"C","selected":false,"answer_text":"Communication arrangement"},
        {"answer_ID":"D","selected":false,"answer_text":"Communication API"},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":18,
    "question_text":"What are the effects of a goods issue posting question? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"It generates an invoice for approval"},
        {"answer_ID":"B","selected":false,"answer_text":"It increases the inventory value"},
        {"answer_ID":"C","selected":false,"answer_text":"It reduces the inventory value"},
        {"answer_ID":"D","selected":false,"answer_text":"It posts an accounting document"},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":19,
    "question_text":"Which embedded support offerings can you make use of in the SAP Fiori Launchpad? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Digital assistance from generative Al (SAP Joule)"},
        {"answer_ID":"B","selected":false,"answer_text":"Assessment questions from the SAP Learning Hub"},
        {"answer_ID":"C","selected":false,"answer_text":"Video tutorials from the SAP Help Portal"},
        {"answer_ID":"D","selected":false,"answer_text":"Recorded system simulations from the Learning Center"},
    {"answer_ID":"E.","selected":false,"answer_text":"Recorded webinars from the SAP Learning Website"}
    ],
    "answer_correct":["A", "C","D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":20,
    "question_text":"When do you perform the goods and invoice receipts reconciliation process? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"When a purchase order is posted, but no invoice has been received"},
        {"answer_ID":"B","selected":false,"answer_text":"When an invoice is posted, but no goods receipt has been received"},
        {"answer_ID":"C","selected":false,"answer_text":"When a purchase order is posted, but no goods receipt has been received"},
        {"answer_ID":"D","selected":false,"answer_text":"When an invoice is posted, but no relevant purchase order is available"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":21,
    "question_text":"Which key benefit does the machine learning component of the SAP Cash Application provide? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Finding patterns without explicitly specifying rules"},
        {"answer_ID":"B","selected":false,"answer_text":"Predictable outcome using static rules"},
        {"answer_ID":"C","selected":false,"answer_text":"Preloaded rule-engines with highly specific process knowledge"},
        {"answer_ID":"D","selected":false,"answer_text":"Rule-based automation using clearly defined processes"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":22,
    "question_text":"What have SAP S/4HANA Cloud Business Workflows been designed for? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"To create cross-product workflow procedures"},
        {"answer_ID":"B","selected":false,"answer_text":"To create very simple release or approval procedures"},
        {"answer_ID":"C","selected":false,"answer_text":"To create complex, repeated work processes with iterative cycles"},
        {"answer_ID":"D","selected":false,"answer_text":"To create standard procedures from SAP Signavio Process Navigator"},
    {"answer_ID":"E.","selected":false,"answer_text":"To create business processes with a high number of people involved in a pre-defined sequence"}
    ],
    "answer_correct":["B", "C" ,"E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":23,
    "question_text":"You have assigned a business role to an end user who has been granted access to only one specific application. While you have a day off, it turns out that the app doesn't show up on the user's Launchpad. What can the user do? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Use the search function to find the app."},
        {"answer_ID":"B","selected":false,"answer_text":"Create a page and assign it to the launchpad space"},
        {"answer_ID":"C","selected":false,"answer_text":"Assign a different business role that provides access."},
        {"answer_ID":"D","selected":false,"answer_text":"Assign a launchpad space to the business role."},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":24,
    "question_text":"What are some reasons you cannot remove a test process from a test plan? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Data binding exists with another process."},
        {"answer_ID":"B","selected":false,"answer_text":"The test plan has already been executed."},
        {"answer_ID":"C","selected":false,"answer_text":"The test process has not been made visible."},
        {"answer_ID":"D","selected":false,"answer_text":"The test plan user has not been created yet."},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":25,
    "question_text":"Which of the following transactions updates values in Profitability Analysis (CO-PA) reporting? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Enter statistical key figure"},
        {"answer_ID":"B","selected":false,"answer_text":"Maintain activity prices"},
        {"answer_ID":"C","selected":false,"answer_text":"Run allocations"},
        {"answer_ID":"D","selected":false,"answer_text":"Post asset acquisition"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":26,
    "question_text":"Which of the following analyses can you create from the Manage KPIs and Reports app? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Multidimensional Reports"},
        {"answer_ID":"B","selected":false,"answer_text":"SAC Stories"},
        {"answer_ID":"C","selected":false,"answer_text":"Review Booklets"},
        {"answer_ID":"D","selected":false,"answer_text":"Object Views"},
    {"answer_ID":"E.","selected":false,"answer_text":"Lumira Dashboards"}
    ],
    "answer_correct":["A", "B", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":27,
    "question_text":"Which tools are used during the Realize phase of an SAP S/4HANA Cloud deployment? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Implementation Guide"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP S/4HANA migration cockpit"},
        {"answer_ID":"C","selected":false,"answer_text":"Product Availability Matrix"},
        {"answer_ID":"D","selected":false,"answer_text":"Manage your Solution application"},
    {"answer_ID":"E.","selected":false,"answer_text":"Test Tool"}
    ],
    "answer_correct":["B", "D" , "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":28,
    "question_text":"What determines the set of data migration objects available from the Migrate Your Data application? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The selected target fields"},
        {"answer_ID":"B","selected":false,"answer_text":"The selected source system"},
        {"answer_ID":"C","selected":false,"answer_text":"The selected business scenarios"},
        {"answer_ID":"D","selected":false,"answer_text":"The customer legacy system"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":29,
    "question_text":"Which editing options are available in the dunning proposal list? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Edit dunning blocks at line item level"},
        {"answer_ID":"B","selected":false,"answer_text":"Change the dunning level of an open item"},
        {"answer_ID":"C","selected":false,"answer_text":"Edit dunning blocks at account level"},
        {"answer_ID":"D","selected":false,"answer_text":"Editing the dunning texts"},
    {"answer_ID":"E.","selected":false,"answer_text":"Change the dunning charges"}
    ],
    "answer_correct":["A", "B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":30,
    "question_text":"Which activity do you perform during the data load preparation? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Fill in migration templates with legacy data"},
        {"answer_ID":"B","selected":false,"answer_text":"Resolve data issues from the simulation process"},
        {"answer_ID":"C","selected":false,"answer_text":"Simulate data load in the new cloud system"},
        {"answer_ID":"D","selected":false,"answer_text":"Define specifications for data extraction on legacy system"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":31,
    "question_text":"When a customer has a cloud-centric landscape, which technologies should you use to integrate SAP S/4HANA Cloud with another SAP Cloud solution? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP S/4HANA Cloud with another SAP Cloud solution"},
        {"answer_ID":"B","selected":false,"answer_text":"Predelivery APIs"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Process Orchestration"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Cloud Connector"},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":32,
    "question_text":"Which tool do you use to develop your own cloud applications for SAP S/4HANA Cloud? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Cloud SDK"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP HANA Studio"},
        {"answer_ID":"C","selected":false,"answer_text":"SAPUI5 SDK"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP ADK"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":33,
    "question_text":"Which layer of SAP S/4HANA provides a universal language to read and process data across different applications? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Business Suite"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP HANA"},
        {"answer_ID":"C","selected":false,"answer_text":"Core Data Services"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Fiori"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":34,
    "question_text":"You are configuring cash operations. To which object do you assign the cash management flow types? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"General ledger accounts"},
        {"answer_ID":"B","selected":false,"answer_text":"Liquidity items"},
        {"answer_ID":"C","selected":false,"answer_text":"Bank account types"},
        {"answer_ID":"D","selected":false,"answer_text":"Planning levels"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":35,
    "question_text":"How do you identify errors in a test plan? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Re-run the test plan."},
        {"answer_ID":"B","selected":false,"answer_text":"Review failed process steps."},
        {"answer_ID":"C","selected":false,"answer_text":"Review the detailed action log."},
        {"answer_ID":"D","selected":false,"answer_text":"Review canceled actions."},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":36,
    "question_text":"Which tool do you use to change or add business rules and business logic in the key user extensibility scenario? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Cloud ABAP Web Editor"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Cloud Platform"},
        {"answer_ID":"C","selected":false,"answer_text":"Eclipse"},
        {"answer_ID":"D","selected":false,"answer_text":"ABAP Workbench"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":37,
    "question_text":"What can you use the Query Browser app to do? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Modify an SAP-created analytical query"},
        {"answer_ID":"B","selected":false,"answer_text":"Create and publish a query"},
        {"answer_ID":"C","selected":false,"answer_text":"Create your own tags for CDS views"},
        {"answer_ID":"D","selected":false,"answer_text":"View authorized published queries"},
    ],
    "answer_correct":["C","D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":38,
    "question_text":"How is the productive system configured? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Via the Cloud Application Lifecycle Management (CALM) Implementation Portal"},
        {"answer_ID":"B","selected":false,"answer_text":"Through Central Business Configuration (CBC) in SAP Business Technology Platform"},
        {"answer_ID":"C","selected":false,"answer_text":"Through transports from the quality system"},
        {"answer_ID":"D","selected":false,"answer_text":"Through the Solution Documentation component of the SAP Solution Manager"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":39,
    "question_text":"How does an implementation consultant support the customer during Fit-to-Standard workshops? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Determine set up instructions for Customer-Driven Integrations."},
        {"answer_ID":"B","selected":false,"answer_text":"Highlight gaps that require decisions on configuration or extensibility."},
        {"answer_ID":"C","selected":false,"answer_text":"Demonstrate SAP Best Practices in the starter system."},
        {"answer_ID":"D","selected":false,"answer_text":"Conduct end-user training on all active scope items."},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":40,
    "question_text":"What is a service order in the Service Line of Business? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"A confirmation of an agreement between a service provider and service recipient."},
        {"answer_ID":"B","selected":false,"answer_text":"Long-term agreement with a customer that defines the content and scope of services guaranteed within specific tolerance limits for certain parameters (e.g. predefined times frames)."},
        {"answer_ID":"C","selected":false,"answer_text":"Short-term agreement between a service provider and a service recipient with the information relevant to the specific service process."},
        {"answer_ID":"D","selected":false,"answer_text":"Outline agreement with a business partner that defines services offered for a particular period."},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":"Not 100% sure but I would say C. A seems correct as well but C is also correct and more detailed."
},
{
    "chapter_ID":1,
    "question_ID":41,
    "question_text":"Where are the manual test cases created for customer User Acceptance Testing? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Solution Manager"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Cloud ALM"},
        {"answer_ID":"C","selected":false,"answer_text":"Test Automation Tool"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Signavio Process Navigator"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":42,
    "question_text":"In the Organizational Structure app in SAP Central Business Configuration, which of the following entities can be added under the Company root node? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Plant"},
        {"answer_ID":"B","selected":false,"answer_text":"Sales Organization"},
        {"answer_ID":"C","selected":false,"answer_text":"Division"},
        {"answer_ID":"D","selected":false,"answer_text":"Purchasing Organization"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":43,
    "question_text":"You want to perform scripted calculations in your planning model. How can you achieve this on the SAP Analytics Cloud? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Input Tasks"},
        {"answer_ID":"B","selected":false,"answer_text":"Data Actions"},
        {"answer_ID":"C","selected":false,"answer_text":"Data Wrangling"},
        {"answer_ID":"D","selected":false,"answer_text":"Value Driver Trees"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":44,
    "question_text":"What are some characteristics of SAP HANA Enterprise Cloud? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"It is a Platform as a Service (PaaS) plus managed services."},
        {"answer_ID":"B","selected":false,"answer_text":"Services include provisioning, patching, and upgrading."},
        {"answer_ID":"C","selected":false,"answer_text":"It is an Infrastructure as a Service (IaaS) plus managed services."},
        {"answer_ID":"D","selected":false,"answer_text":"SAP runs your application in a shared environment."},
    {"answer_ID":"E.","selected":false,"answer_text":"Customers can bring their own licensed SAP applications."}
    ],
    "answer_correct":["B", "C" ,"E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":45,
    "question_text":"To which table does the SAP S/4HANA Cloud system write commitment values? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"ACDOCA"},
        {"answer_ID":"B","selected":false,"answer_text":"ACDOCC"},
        {"answer_ID":"C","selected":false,"answer_text":"ACDOCP"},
        {"answer_ID":"D","selected":false,"answer_text":"ACDOCU"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":46,
    "question_text":"A customer needs detailed customization to the standard forms and email templates. What solution should the consultant propose? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Use the Output Management apps to customize a standard template"},
        {"answer_ID":"B","selected":false,"answer_text":"Use the Adobe Lifecycle Designer tool to edit a standard template"},
        {"answer_ID":"C","selected":false,"answer_text":"Use the corresponding configuration items to modify elements of the form template"},
        {"answer_ID":"D","selected":false,"answer_text":"Create a custom UI with the Custom Fields and Logic app"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":47,
    "question_text":"How can you identify an asset number as a main asset number? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"By the lack of prefix for the asset"},
        {"answer_ID":"B","selected":false,"answer_text":"By the description of the asset"},
        {"answer_ID":"C","selected":false,"answer_text":"By the prefix -0 after the asset number"},
        {"answer_ID":"D","selected":false,"answer_text":"By the prefix -1 after the asset number"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":48,
    "question_text":"Why is de-coupling your extensions from SAP S/4HANA Cloud required? (X) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"To guarantee the stability of the system"},
        {"answer_ID":"B","selected":false,"answer_text":"To ensure extensions do not conflict with SAP software updates"},
        {"answer_ID":"C","selected":false,"answer_text":"To enhance existing functionality within the application itself"},
        {"answer_ID":"D","selected":false,"answer_text":"To allow customers to take advantage of predefined user exits"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":49,
    "question_text":"After integration requirements have been finalized, what is used to analyze, design, and document the integration strategy? (X – certif) (X) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Cloud ALM Requirements app"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Business Accelerator Hub"},
        {"answer_ID":"C","selected":false,"answer_text":"Integration and API List"},
        {"answer_ID":"D","selected":false,"answer_text":"Integration Solution Advisory Methodology"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":50,
    "question_text":"What does the valuation area control when you execute a foreign currency valuation run? (X – certif) (X) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The document type of the valuation posting"},
        {"answer_ID":"B","selected":false,"answer_text":"The exchange rate type to calculate the valuation differences"},
        {"answer_ID":"C","selected":false,"answer_text":"The valuation procedure"},
        {"answer_ID":"D","selected":false,"answer_text":"The posting mode of the valuation run"},
    {"answer_ID":"E.","selected":false,"answer_text":"The posting period of the valuation posting"}
    ],
    "answer_correct":["A", "B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":51,
    "question_text":"What are semantic tags used for in SAP S/4HANA Cloud? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"To report on differing valuation versions"},
        {"answer_ID":"B","selected":false,"answer_text":"To indicate the functional areas in which costs are incurred"},
        {"answer_ID":"C","selected":false,"answer_text":"To separate local and international accounts"},
        {"answer_ID":"D","selected":false,"answer_text":"To calculate KPI’s in analytics reports"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":52,
    "question_text":"Which tools does SAP provide to make it easier for customers to maintain their SAP S/4HANA Cloud systems after a release upgrade? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"What's New Viewer Tool"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Signavio Process Navigator Tool"},
        {"answer_ID":"C","selected":false,"answer_text":"Release Assessment and Scope Dependency Tool"},
        {"answer_ID":"D","selected":false,"answer_text":"Test Automation Tool"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":53,
    "question_text":"How can you process recurring entries? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"You can post recurring entries in standard posting apps, selecting the document type RE."},
        {"answer_ID":"B","selected":false,"answer_text":"You can create a recurring entry without defining a recurrence start date."},
        {"answer_ID":"C","selected":false,"answer_text":"You can post recurring entries only through the recurring entry program."},
        {"answer_ID":"D","selected":false,"answer_text":"You can schedule recurring entries to start automatically."},
    {"answer_ID":"E.","selected":false,"answer_text":"You can create a recurring invoice entry based on a template invoice."}
    ],
    "answer_correct":["C", "D", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":54,
    "question_text":"You need to produce additional financial statements according to Local GAAP requirements. Which dimension do you use in reporting? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Ledger group"},
        {"answer_ID":"B","selected":false,"answer_text":"Journal entry type"},
        {"answer_ID":"C","selected":false,"answer_text":"Functional area"},
        {"answer_ID":"D","selected":false,"answer_text":"Profit center group"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":55,
    "question_text":"Which data is generated when you activate a business role in identity and access management? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Default values"},
        {"answer_ID":"B","selected":false,"answer_text":"Business catalogs"},
        {"answer_ID":"C","selected":false,"answer_text":"Business groups"},
        {"answer_ID":"D","selected":false,"answer_text":"PFCG roles"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":56,
    "question_text":"Which tool do you use to run the SAP cloud Integration Automation Service for SAP S/4HANA Cloud? (X – certif) - Choose one:" ,
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Activate Roadmap"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Maintenance Planner"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Solution Manager"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Best Practices Explorer"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":57,
    "question_text":"In SAP Central Business Configuration, which activities can you perform in the Product-Specific Configuration Phase? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Modify building blocks."},
        {"answer_ID":"B","selected":false,"answer_text":"Add blocking reasons for billing."},
        {"answer_ID":"C","selected":false,"answer_text":"Change approval thresholds."},
        {"answer_ID":"D","selected":false,"answer_text":"Add new sales organizations."},
    {"answer_ID":"E.","selected":false,"answer_text":"Create new scope items."}
    ],
    "answer_correct":["B", "C" , "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":58,
    "question_text":"You configure Bank Account Management. To which of the following objects do you assign approval patterns to manage bank accounts? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Company code"},
        {"answer_ID":"B","selected":false,"answer_text":"Account type"},
        {"answer_ID":"C","selected":false,"answer_text":"Bank account ID"},
        {"answer_ID":"D","selected":false,"answer_text":"Payment signatory"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":59,
    "question_text":"Which characteristic must be unique for each cash journal? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"G/L account"},
        {"answer_ID":"B","selected":false,"answer_text":"Tax code"},
        {"answer_ID":"C","selected":false,"answer_text":"Currency"},
        {"answer_ID":"D","selected":false,"answer_text":"Business transaction"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":60,
    "question_text":"Which of the following configuration apps (SSCUIs) are used in SAP S/4HANA for Legal Content (1XV)? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Define Functional Area"},
        {"answer_ID":"B","selected":false,"answer_text":"Define Entity Types"},
        {"answer_ID":"C","selected":false,"answer_text":"Define Content Types"},
        {"answer_ID":"D","selected":false,"answer_text":"Define Document Types"},
    {"answer_ID":"E.","selected":false,"answer_text":"Define Access Level"}
    ],
    "answer_correct":["A", "B", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":61,
    "question_text":"You want to authenticate employees in SAP Cloud Identity. In which app can you download a .CSV file with the list of users? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Maintain communication users"},
        {"answer_ID":"B","selected":false,"answer_text":"Maintain business roles"},
        {"answer_ID":"C","selected":false,"answer_text":"Maintain catalog roles"},
        {"answer_ID":"D","selected":false,"answer_text":"Maintain business users"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":62,
    "question_text":"Which fields can be changed in a posted invoice? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Reference"},
        {"answer_ID":"B","selected":false,"answer_text":"Payment terms"},
        {"answer_ID":"C","selected":false,"answer_text":"Profit center"},
        {"answer_ID":"D","selected":false,"answer_text":"Invoice amount"},
    {"answer_ID":"E.","selected":false,"answer_text":"Line-item text"}
    ],
    "answer_correct":["B", "C" ,"E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":63,
    "question_text":"Which data is entered in the asset migration file for each asset master record? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Accumulated depreciation values in company code currency"},
        {"answer_ID":"B","selected":false,"answer_text":"Cumulative acquisition values in all currencies"},
        {"answer_ID":"C","selected":false,"answer_text":"Cumulative acquisition values in group currency"},
        {"answer_ID":"D","selected":false,"answer_text":"Accumulated depreciation values in group currency"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":64,
    "question_text":"Which organizational units can form a sales area in SAP S/4HANA Cloud? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The sales organization, the distribution channel, and the division"},
        {"answer_ID":"B","selected":false,"answer_text":"The sales organization, the distribution channel, and the shipping point"},
        {"answer_ID":"C","selected":false,"answer_text":"The sales organization, the delivering plant, and the division"},
        {"answer_ID":"D","selected":false,"answer_text":"The company code, the plant, and the sales organization"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":65,
    "question_text":"What are some responsibilities of a customer centre of Expertise (CCOE)? (X) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Reduce the total cost of ownership for IT."},
        {"answer_ID":"B","selected":false,"answer_text":"Determine KPIs that will support a continuous improvement mindset."},
        {"answer_ID":"C","selected":false,"answer_text":"Serve as a central contact for SAP and manage interactions with the SAP Service Center."},
        {"answer_ID":"D","selected":false,"answer_text":"Conduct risk-based assessments to determine the value of activating new processes."},
    {"answer_ID":"E.","selected":false,"answer_text":"Ensure the resources necessary for the project's success are available."}
    ],
    "answer_correct":["A", "B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":66,
    "question_text":"What are your options if an out-of-the-box integration between SAP S/4HANA Cloud and another SAP solution is NOT available? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Integration tools"},
        {"answer_ID":"B","selected":false,"answer_text":"Infrastructure as a Service"},
        {"answer_ID":"C","selected":false,"answer_text":"Custom CDS Views"},
        {"answer_ID":"D","selected":false,"answer_text":"Unrestricted firewall"},
    ],
    "answer_correct":["A","C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":67,
    "question_text":"How can you post accruals in SAP S/4HANA Cloud? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Use document parking"},
        {"answer_ID":"B","selected":false,"answer_text":"Post a recurring entry (Dauerauftrag buchen)"},
        {"answer_ID":"C","selected":false,"answer_text":"Use the accrual engine"},
        {"answer_ID":"D","selected":false,"answer_text":"Use the Microsoft Excel upload"},
    {"answer_ID":"E.","selected":false,"answer_text":"Enter them manually"}
    ],
    "answer_correct":["B", "D" , "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":68,
    "question_text":"Which of the following fields in maintained on the general data segment of the supplier business partner? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Communication language"},
        {"answer_ID":"B","selected":false,"answer_text":"Tolerance group"},
        {"answer_ID":"C","selected":false,"answer_text":"Payment terms"},
        {"answer_ID":"D","selected":false,"answer_text":"Reconciliation account"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":69,
    "question_text":"What does the journal entry type control? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The transaction currency types allowed to be posted"},
        {"answer_ID":"B","selected":false,"answer_text":"The types of accounts allowed to be posted to"},
        {"answer_ID":"C","selected":false,"answer_text":"Whether a posting posts a debit or credit value"},
        {"answer_ID":"D","selected":false,"answer_text":"The ledgers updated by the posting values"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":70,
    "question_text":"Which data from third-party credit agencies is provided via the SAP S/4HANA Finance Cloud for credit integration solution? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Legal form"},
        {"answer_ID":"B","selected":false,"answer_text":"Industry code"},
        {"answer_ID":"C","selected":false,"answer_text":"Tax identification number"},
        {"answer_ID":"D","selected":false,"answer_text":"Payment behavior"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":71,
    "question_text":"Which business scenarios does the Two-Tier ERP deployment scenario offer? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Single-source synchronous system update implementation"},
        {"answer_ID":"B","selected":false,"answer_text":"Centrally managed ticketing and support service network"},
        {"answer_ID":"C","selected":false,"answer_text":"Reporting and analytics across the subsidiary ecosystem"},
        {"answer_ID":"D","selected":false,"answer_text":"Integration of new acquisitions to the corporate infrastructure"},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":72,
    "question_text":"Which of the following describes a contract object in SAP S/4HANA Cloud for Contract and Lease Management? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Service contract"},
        {"answer_ID":"B","selected":false,"answer_text":"Real Estate"},
        {"answer_ID":"C","selected":false,"answer_text":"Sublease contract"},
        {"answer_ID":"D","selected":false,"answer_text":"Machinery"},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":73,
    "question_text":"Where can you find content to set up an integration if there is no SAP Best Practices content available? (X – certif) (X) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Fiori Apps Reference Library"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP BTP Cockpit"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP API Business Hub"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Extensibility Explorer"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":74,
    "question_text":"Which activities can you perform when you use general ledger accounting in SAP S/4HANA Cloud? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Carry out recurring entries"},
        {"answer_ID":"B","selected":false,"answer_text":"Post to the financial statement version"},
        {"answer_ID":"C","selected":false,"answer_text":"Display general ledger items, documents and good balance"},
        {"answer_ID":"D","selected":false,"answer_text":"Release blocked sales orders"},
    ],
    "answer_correct":["A","C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":75,
    "question_text":"Which tasks are mandatory before you can migrate data for a specific object? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Permission to migrate the data has been assigned"},
        {"answer_ID":"B","selected":false,"answer_text":"Predecessor objects have been migrated"},
        {"answer_ID":"C","selected":false,"answer_text":"All previous migration projects are in the Finished status"},
        {"answer_ID":"D","selected":false,"answer_text":"You select the same migration method previously used for other objects"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":76,
    "question_text":"Which master record object controls the use of an business partner in accounts payable? (X – certif) (X) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Business partner category"},
        {"answer_ID":"B","selected":false,"answer_text":"Business partner role"},
        {"answer_ID":"C","selected":false,"answer_text":"Business partner classification"},
        {"answer_ID":"D","selected":false,"answer_text":"Business partner grouping"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":77,
    "question_text":"What are the restrictions when posting general journal entries to special periods? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"You must only post to balance sheet accounts."},
        {"answer_ID":"B","selected":false,"answer_text":"You must manually enter the specific special period you are posting to."},
        {"answer_ID":"C","selected":false,"answer_text":"You must use the document type YE (year end)."},
        {"answer_ID":"D","selected":false,"answer_text":"You must specify a posting date in the last period of the fiscal year."},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":78,
    "question_text":"What are key features of the global accounting hierarchies? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Hierarchy time dependency"},
        {"answer_ID":"B","selected":false,"answer_text":"Hierarchy status control"},
        {"answer_ID":"C","selected":false,"answer_text":"Hierarchy management workflow"},
        {"answer_ID":"D","selected":false,"answer_text":"Multiple parallel hierarchies"},
    ],
    "answer_correct":["A","B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":79,
    "question_text":"After approving a journal entry as a processor, you see the system has marked the posting as failed. How do you resolve this? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Create a new journal entry with the correct data and post it"},
        {"answer_ID":"B","selected":false,"answer_text":"Correct the indicated errors in the original entry and post it"},
        {"answer_ID":"C","selected":false,"answer_text":"Ask the requester to correct the journal entry and resubmit it"},
        {"answer_ID":"D","selected":false,"answer_text":"Ask the requester to create a new entry with the correct data and resubmit it"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":80,
    "question_text":"Which tool is used for the liquidity planning in SAP S/4HANA Cloud? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Integrated Business Planning"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP BPC Optimized for SAP S/4HANA"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Liquidity Planner"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Analytics Cloud"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":81,
    "question_text":"What must be defined in SAP Central Business Configuration prior to creating the organizational structure? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Leading ledger"},
        {"answer_ID":"B","selected":false,"answer_text":"Chart of accounts"},
        {"answer_ID":"C","selected":false,"answer_text":"Group currency"},
        {"answer_ID":"D","selected":false,"answer_text":"Fiscal year variant"},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":82,
    "question_text":"Which activities are completed in SAP Central Business configuration? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Activate countries not covered by SAP Best Practices."},
        {"answer_ID":"B","selected":false,"answer_text":"Add extensions to standard processes."},
        {"answer_ID":"C","selected":false,"answer_text":"Create customer-specific master data."},
        {"answer_ID":"D","selected":false,"answer_text":"Add customer-specific data within SAP Best Practices content."},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":83,
    "question_text":"Which cloud computing service enables you to build and deploy applications? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"IaaS"},
        {"answer_ID":"B","selected":false,"answer_text":"IPaaS"},
        {"answer_ID":"C","selected":false,"answer_text":"SaaS"},
        {"answer_ID":"D","selected":false,"answer_text":"PaaS"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":84,
    "question_text":"When using the local SAP S/4HANA Database Schema migration approach, what is the maxim file size? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"100 MB per ZIP file"},
        {"answer_ID":"B","selected":false,"answer_text":"160 MB per file"},
        {"answer_ID":"C","selected":false,"answer_text":"160 MB per ZIP file"},
        {"answer_ID":"D","selected":false,"answer_text":"100 MB per file"},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":85,
    "question_text":"SAP Activate for SAP S/4HANA Cloud SAP Activate. Lifecycle Management, and Best Practices How can customers activate additional SAP Best Practices processes? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Request activation in the Production system after it is upgraded to the latest quarter's release"},
        {"answer_ID":"B","selected":false,"answer_text":"Request activation in the Quality system after it is upgraded to the latest quarter's release"},
        {"answer_ID":"C","selected":false,"answer_text":"Request activation in the Quality system from the SAP Service Center during the Run phase"},
        {"answer_ID":"D","selected":false,"answer_text":"Request activation in the Starter system through the SAP Cloud Platform during the Realize phase"},
    {"answer_ID":"E.","selected":false,"answer_text":"Request activation in the Starter system from the SAP Service Center during the Deploy phase"}
    ],
    "answer_correct":["B", "C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":86,
    "question_text":"How can business users create new analytics content? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Change underlying database tables of standard data sources"},
        {"answer_ID":"B","selected":false,"answer_text":"Create new data sources via joins of standard data sources"},
        {"answer_ID":"C","selected":false,"answer_text":"Create additional key figures in existing data sources"},
        {"answer_ID":"D","selected":false,"answer_text":"Combine current and historical data"},
    {"answer_ID":"E.","selected":false,"answer_text":"Change existing data sources by adding filters"}
    ],
    "answer_correct":["B", "C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":87,
    "question_text":"An SAP Fiori app isn't displaying on the SAP Fiori Launchpad even though the business role granting permission to the app is correctly assigned to the user. How would you troubleshoot? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Check the Role Maintenance app."},
        {"answer_ID":"B","selected":false,"answer_text":"Check the space and page(s) assigned to the role."},
        {"answer_ID":"C","selected":false,"answer_text":"Check the business role template."},
        {"answer_ID":"D","selected":false,"answer_text":"Check the business catalogues assigned to the role."},
    {"answer_ID":"E.","selected":false,"answer_text":"Check the restrictions for the role."}
    ],
    "answer_correct":["A", "B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":88,
    "question_text":"The general valuation class is assigned to which of the following objects in the self-service configuration (SSC)? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Business partner"},
        {"answer_ID":"B","selected":false,"answer_text":"Transaction type"},
        {"answer_ID":"C","selected":false,"answer_text":"Company code"},
        {"answer_ID":"D","selected":false,"answer_text":"Product type"},
    {"answer_ID":"E.","selected":false,"answer_text":"Flow type"}
    ],
    "answer_correct":["B", "D", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":89,
    "question_text":"Which of the below pricing types are available within the application “Manage Bank Fee Conditions”? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Flat pricing"},
        {"answer_ID":"B","selected":false,"answer_text":"Group pricing"},
        {"answer_ID":"C","selected":false,"answer_text":"Tier pricing"},
        {"answer_ID":"D","selected":false,"answer_text":"Bulk pricing"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":90,
    "question_text":"Which asset accounting relevant postings are made periodically, typically at the end of the month? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Post asset acquisition via clearing account"},
        {"answer_ID":"B","selected":false,"answer_text":"Depreciation Posting"},
        {"answer_ID":"C","selected":false,"answer_text":"Post transfer of an asset"},
        {"answer_ID":"D","selected":false,"answer_text":"Settlement of the WBS element"},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":91,
    "question_text":"In what phase of the SAP Activate methodology do you test the business processes? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Explore"},
        {"answer_ID":"B","selected":false,"answer_text":"Deploy"},
        {"answer_ID":"C","selected":false,"answer_text":"Realize"},
        {"answer_ID":"D","selected":false,"answer_text":"Prepare"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":92,
    "question_text":"Which dimensions can be used to provide complete balance sheet reports? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Cost center"},
        {"answer_ID":"B","selected":false,"answer_text":"Functional area"},
        {"answer_ID":"C","selected":false,"answer_text":"Profit center"},
        {"answer_ID":"D","selected":false,"answer_text":"Segment"},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":93,
    "question_text":"What tools enable non-developers to create low/no-code extensions? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Business Application Studio"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Build"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Fiori extensibility apps"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Cloud SDK"},
    ],
    "answer_correct":["A", "B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":94,
    "question_text":"You use the app “Manage Journal Entries” to display changes to journal entries Which changes can be part of the list? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Amount that was changed"},
        {"answer_ID":"B","selected":false,"answer_text":"Date of the change"},
        {"answer_ID":"C","selected":false,"answer_text":"G/L account that was changed"},
        {"answer_ID":"D","selected":false,"answer_text":"User who made the change"},
    ],
    "answer_correct":["B","D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":95,
    "question_text":"You need to provide historical data as training input for SAP Cash Application. Which data can you use to train the application? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Electronic bank statements"},
        {"answer_ID":"B","selected":false,"answer_text":"Dunning notices"},
        {"answer_ID":"C","selected":false,"answer_text":"Payment advices"},
        {"answer_ID":"D","selected":false,"answer_text":"Liquidity forecast"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":96,
    "question_text":"In which structure do you define where the accounts appear in the financial statement? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Accounting principle"},
        {"answer_ID":"B","selected":false,"answer_text":"Chart of accounts"},
        {"answer_ID":"C","selected":false,"answer_text":"Financial statement version"},
        {"answer_ID":"D","selected":false,"answer_text":"Ledger group"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":97,
    "question_text":"What are customers able to scope in SAP S/4HANA Cloud with Central Business Configuration (CBC)? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Non-standard scenarios"},
        {"answer_ID":"B","selected":false,"answer_text":"Pre-defined scenario bundles"},
        {"answer_ID":"C","selected":false,"answer_text":"Countries where business processes will run"},
        {"answer_ID":"D","selected":false,"answer_text":"Scope extensions"},
    {"answer_ID":"E.","selected":false,"answer_text":"Public sector processes"}
    ],
    "answer_correct":["B", "C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":98,
    "question_text":"Which asset characteristic controls if an asset is under construction? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Depreciation key"},
        {"answer_ID":"B","selected":false,"answer_text":"Settlement profile"},
        {"answer_ID":"C","selected":false,"answer_text":"Asset class"},
        {"answer_ID":"D","selected":false,"answer_text":"Capitalization date"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":99,
    "question_text":"SAP S/4HANA Cloud Configuration and User Management Which customizing activates are allowed though the self-service configuration UI? (X) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Addition of new custom fields within SAP Best Practices content"},
        {"answer_ID":"B","selected":false,"answer_text":"Addition of new SAP Best Practices business process"},
        {"answer_ID":"C","selected":false,"answer_text":"Adaptation of fields within SAP Best Practices content"},
        {"answer_ID":"D","selected":false,"answer_text":"Deletion of SAP Best Practices content"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":100,
    "question_text":"What does a partner line of business configuration expert reference when identifying business processes to cover in the Fit-to-Standard workshops? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Signavio Journey Modeler"},
        {"answer_ID":"B","selected":false,"answer_text":"Digital Discovery Assessment"},
        {"answer_ID":"C","selected":false,"answer_text":"Business Driven Configuration Questionnaire"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Signavio Process Collaboration Hub"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":101,
    "question_text":"Where do you find industry cloud solutions to address a customer's unique requirement? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Signavio Process Navigator"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Discovery Center"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Business Technology Platform"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Store"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":102,
    "question_text":"Based on the SAP Activate methodology, which activities must you perform in the Explore phase? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Configure the organizational structure and chart of accounts in the system"},
        {"answer_ID":"B","selected":false,"answer_text":"Conduct the Fit-to-Standard workshops to enable customer business process experts and identify gaps"},
        {"answer_ID":"C","selected":false,"answer_text":"Use the Quality system to demonstrate SAP Best Practices processes"},
        {"answer_ID":"D","selected":false,"answer_text":"Use the Starter system to demonstrate SAP Best Practices processes"},
    ],
    "answer_correct":["B", "D"]
    },
{
    "chapter_ID":1,
    "question_ID":103,
    "question_text":"What tasks can you perform from the Display Technical Users app? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Change the username and password of a print user."},
        {"answer_ID":"B","selected":false,"answer_text":"Assign and unassign users to user groups."},
        {"answer_ID":"C","selected":false,"answer_text":"Lock and unlock the initial user account that is delivered with the new system."},
        {"answer_ID":"D","selected":false,"answer_text":"Upload a certificate for a communication user."},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":104,
    "question_text":"In which task is the preliminary configuration information gathered to help the configuration experts gain insight into the customer’s current processes? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Solution Definition"},
        {"answer_ID":"B","selected":false,"answer_text":"Business Driven Configuration Assessment"},
        {"answer_ID":"C","selected":false,"answer_text":"Digital Discovery Assessment"},
        {"answer_ID":"D","selected":false,"answer_text":"Fit-to-Standard Workshops"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":105,
    "question_text":"Which organizational unit do you use to produce materials or provide goods and services? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Company Code"},
        {"answer_ID":"B","selected":false,"answer_text":"Plant"},
        {"answer_ID":"C","selected":false,"answer_text":"Sales organization"},
        {"answer_ID":"D","selected":false,"answer_text":"Storage location"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":106,
    "question_text":"What is the purpose of the Starter system in the SAP S/4HANA Cloud implementation project? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"To demonstrate SAP Best Practices processes in a preconfigured system and identify customer personalization requirements"},
        {"answer_ID":"B","selected":false,"answer_text":"To demonstrate the customer’s personalized solution, including process flows, the organizational structure, and authorizations"},
        {"answer_ID":"C","selected":false,"answer_text":"To configure the customer’s personalized solution for demonstration and testing during the Realize phase"},
        {"answer_ID":"D","selected":false,"answer_text":"To conduct a blueprint workshop to identify all customer requirements for a completely customized solution"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":107,
    "question_text":"Which settings does the account group control when creating a new G/L account? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The tax categories you can assign to the account"},
        {"answer_ID":"B","selected":false,"answer_text":"The field status of the field “group account number”"},
        {"answer_ID":"C","selected":false,"answer_text":"The G/L account types you can assign to the account"},
        {"answer_ID":"D","selected":false,"answer_text":"The number range in which the new account can be created"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":108,
    "question_text":"What is the relation between the line items displayed for a journal entry in the entry view and the general ledger view? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The entry view displays the subledger accounts (such as customer or supplier) while this is not available in the general ledger view."},
        {"answer_ID":"B","selected":false,"answer_text":"The entry view has as many or more line items as the general ledger view."},
        {"answer_ID":"C","selected":false,"answer_text":"The general ledger view displays account assignments such as the profit center while these are not available in the entry view"},
        {"answer_ID":"D","selected":false,"answer_text":"The general ledger view has as many or more line items as the entry view."},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":109,
    "question_text":"What are the steps for conducting automated business process testing? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Create a test system"},
        {"answer_ID":"B","selected":false,"answer_text":"Run the test"},
        {"answer_ID":"C","selected":false,"answer_text":"Correct and rerun failed test processes"},
        {"answer_ID":"D","selected":false,"answer_text":"Assess data management"},
    {"answer_ID":"E.","selected":false,"answer_text":"Create a test plan"}
    ],
    "answer_correct":["A", "B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":110,
    "question_text":"How are analytics capabilities built into SAP S/4HANA Cloud? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"They are installed on a dedicated SAP Fiori front-end server"},
        {"answer_ID":"B","selected":false,"answer_text":"They are extended by the user to a dedicated SAP Fiori front-end server"},
        {"answer_ID":"C","selected":false,"answer_text":"They are installed as an add-on to the application"},
        {"answer_ID":"D","selected":false,"answer_text":"They are embedded in the same technical stack as the application"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":111,
    "question_text":"According to the SAP Activate methodology, in which phase are the scope item Fit-to-Standard workshops executed? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Prepare"},
        {"answer_ID":"B","selected":false,"answer_text":"Realize"},
        {"answer_ID":"C","selected":false,"answer_text":"Deploy"},
        {"answer_ID":"D","selected":false,"answer_text":"Explore"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":112,
    "question_text":"What SAP Fiori app do you use to migrate new bank data to SAP S/4HANA? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Manage Bank Accounts"},
        {"answer_ID":"B","selected":false,"answer_text":"Manage Banks"},
        {"answer_ID":"C","selected":false,"answer_text":"Manage Your Solution"},
        {"answer_ID":"D","selected":false,"answer_text":"Maintain Business Partner"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":113,
    "question_text":"Your company is engaged in foreign commerce. As the buyer of goods you are applying for which instrument to secure the transaction? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Bank Guarantee"},
        {"answer_ID":"B","selected":false,"answer_text":"Zero-Coupon Bond"},
        {"answer_ID":"C","selected":false,"answer_text":"Commercial Paper"},
        {"answer_ID":"D","selected":false,"answer_text":"Letter of Credit"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":114,
    "question_text":"Which action can a trader perform with the trading platform integration in the SAP Cloud Platform? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Release hedge requests from the backend system"},
        {"answer_ID":"B","selected":false,"answer_text":"Split a trade request into several new trade requests"},
        {"answer_ID":"C","selected":false,"answer_text":"Edit trades to add missing information"},
        {"answer_ID":"D","selected":false,"answer_text":"Create cash requests with trading platform profile"},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":115,
    "question_text":"SAP Success Factors Employee central Integration: Where can you update the address details of an employee manually? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"In the business partner master record in SAP S/4HANA Cloud"},
        {"answer_ID":"B","selected":false,"answer_text":"In the employee master record in SAP SuccessFactors"},
        {"answer_ID":"C","selected":false,"answer_text":"In the supplier master record in SAP S/4HANA Cloud"},
        {"answer_ID":"D","selected":false,"answer_text":"In the user master record in SAP SuccessFactors"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":116,
    "question_text":"In which implementation phase do you perform data load preparation? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Deploy"},
        {"answer_ID":"B","selected":false,"answer_text":"Realize"},
        {"answer_ID":"C","selected":false,"answer_text":"Explore"},
        {"answer_ID":"D","selected":false,"answer_text":"Prepare"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":117,
    "question_text":"You post and match an incoming payment to the incorrect customer account. How can you correct the mistake? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Reset the clearing and reverse the document"},
        {"answer_ID":"B","selected":false,"answer_text":"Issue a credit memo to the customer."},
        {"answer_ID":"C","selected":false,"answer_text":"Change the customer on the payment document."},
        {"answer_ID":"D","selected":false,"answer_text":"Contact the customer through correspondence."},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":118,
    "question_text":"Which of the following entities deals with processing the bank account statement and journal entries for bank /credit card settlement accounts? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Contract Accounting"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Digital Payments add-on"},
        {"answer_ID":"C","selected":false,"answer_text":"Point of Sale Systems"},
        {"answer_ID":"D","selected":false,"answer_text":"Financial Accounting"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":119,
    "question_text":"You need to integrate the headquarters of a company running on SAP S/4HANA with a subsidiary running on SAP S/4HANA Cloud. In a 2-tier ERP scenario, which integration technologies can you use? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Open Connectors"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Cloud Connector"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Process Orchestration"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Cloud Integration Automation Service"},
    ],
    "answer_correct":["A", "B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":120,
    "question_text":"Which of the following benefits can be harnessed when using the SAP digital payments add-on? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Enhanced credit card number storage"},
        {"answer_ID":"B","selected":false,"answer_text":"Unlimited number of Payment Service Providers"},
        {"answer_ID":"C","selected":false,"answer_text":"Future proof payment methods"},
        {"answer_ID":"D","selected":false,"answer_text":"Predictable setup and operation costs"},
    ],
    "answer_correct":["A", "B"],
    "score":0,
    "responded":false,
    "explenation":" It can be all of them but I suppose best to stick to A&B"
},
{
    "chapter_ID":1,
    "question_ID":121,
    "question_text":"What are the prerequisites for using the SAP Fiori Test Your Processes app? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The test script are uploaded in the productive system"},
        {"answer_ID":"B","selected":false,"answer_text":"The quality system is connected to the Test Execution Service on SAP Business Technology Platform"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Best Practices are activated in the quality system"},
        {"answer_ID":"D","selected":false,"answer_text":"The productive system is connected to the implementation landscape"},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":122,
    "question_text":"What determinates the set of data migration objects available from the Migrate your data applications? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The selected target fields"},
        {"answer_ID":"B","selected":false,"answer_text":"The selected solutions scope"},
        {"answer_ID":"C","selected":false,"answer_text":"The selected source system"},
        {"answer_ID":"D","selected":false,"answer_text":"The selected business scenarios"},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":123,
    "question_text":"Which of the following fields are maintained on the general data segment of the customer business partner? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Payment method"},
        {"answer_ID":"B","selected":false,"answer_text":"Communication language"},
        {"answer_ID":"C","selected":false,"answer_text":"Bank key"},
        {"answer_ID":"D","selected":false,"answer_text":"Company code"},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":124,
    "question_text":"You are recording actions for a custom process step in a test automate. When do you press the Read button on the recording panel? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"To capture an error message on the screen that can be used later."},
        {"answer_ID":"B","selected":false,"answer_text":"To capture a value in a text field that should be stored as a variable"},
        {"answer_ID":"C","selected":false,"answer_text":"To capture text in a message screen that can be used for data binding later"},
        {"answer_ID":"D","selected":false,"answer_text":"To capture a static label on the screen that should be checked during test execution"},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":125,
    "question_text":"When processing a cross-company code transaction between 3 company codes, what is the minimum number of journal entries posted by the system? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"4"},
        {"answer_ID":"B","selected":false,"answer_text":"3"},
        {"answer_ID":"C","selected":false,"answer_text":"6"},
        {"answer_ID":"D","selected":false,"answer_text":"1"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":126,
    "question_text":"For which activity do you use SAP S/4HANA Cloud for Credit Integration? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Import customer credit ratings from external credit rating agencies"},
        {"answer_ID":"B","selected":false,"answer_text":"Notify an external credit agency about outstanding customer payments"},
        {"answer_ID":"C","selected":false,"answer_text":"Calculate a customer’s real-time credit limit based on a manual credit rating"},
        {"answer_ID":"D","selected":false,"answer_text":"Supplement internal customer data to better monitor a customer’s credit risk"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":127,
    "question_text":"Why do you perform realignment in Profitability Analysis (CO-PA)? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"To correct posted general ledger accounts"},
        {"answer_ID":"B","selected":false,"answer_text":"To correct exchange rate valuation"},
        {"answer_ID":"C","selected":false,"answer_text":"To adjust assigned characteristics"},
        {"answer_ID":"D","selected":false,"answer_text":"To adjust derived values fields"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":128,
    "question_text":"What are the prerequisites for using the SAP Fiori app Test your process? (X) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The test scripts are uploaded to SAP S/4HANA Cloud."},
        {"answer_ID":"B","selected":false,"answer_text":"The development system is connected to the implementation landscape."},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Best Practices are activated in the system and connected to Test Engine on SAP cloud platform."},
        {"answer_ID":"D","selected":false,"answer_text":"Test user has all the necessary roles to run the process."},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":129,
    "question_text":"Which date determines the period of the asset acquisition? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Posting Date"},
        {"answer_ID":"B","selected":false,"answer_text":"Document Date"},
        {"answer_ID":"C","selected":false,"answer_text":"Asset Value Date"},
        {"answer_ID":"D","selected":false,"answer_text":"Base Date"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":130,
    "question_text":"Which activities can you execute in the Maintain Business Roles SAP Fiori app? (X – certif) (X) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Maintain restrictions"},
        {"answer_ID":"B","selected":false,"answer_text":"Maintain catalog roles"},
        {"answer_ID":"C","selected":false,"answer_text":"Assign PFCG profiles to a business role"},
        {"answer_ID":"D","selected":false,"answer_text":"Assign business catalogs to a business role"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":131,
    "question_text":"At which point in the integrated sales process is a balance sheet relevant accounting document created? (X – certif) (X) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Quotation"},
        {"answer_ID":"B","selected":false,"answer_text":"Sales order confirmation"},
        {"answer_ID":"C","selected":false,"answer_text":"Sales order creation"},
        {"answer_ID":"D","selected":false,"answer_text":"Goods issue"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":132,
    "question_text":"You are implementing SAP S/4HANA Cloud for a company that manufactures bicycles and bicycle parts. The accountant is confused with the available account types. When creating an account for costs of printing marketing materials, which account type should they - choose? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Balance Sheet"},
        {"answer_ID":"B","selected":false,"answer_text":"Non-operating expense"},
        {"answer_ID":"C","selected":false,"answer_text":"Primary cost account"},
        {"answer_ID":"D","selected":false,"answer_text":"Secondary cost account"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":133,
    "question_text":"If you cannot find a prepackaged solution process that addresses integration requirements in SAP Signavio Process Navigator, where do you look next? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Cloud ALM"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Business Technology Platform"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Business Accelerator Hub"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Discovery Center"},
    ],
    "answer_correct": ["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":134,
    "question_text":"Which assignment does the cost center category control? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Profit center"},
        {"answer_ID":"B","selected":false,"answer_text":"Funds center"},
        {"answer_ID":"C","selected":false,"answer_text":"Functional area"},
        {"answer_ID":"D","selected":false,"answer_text":"Business area"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":135,
    "question_text":"Which business objects were initially released as part of the SAP One Domain Model? (X) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Bank"},
        {"answer_ID":"B","selected":false,"answer_text":"Workforce person"},
        {"answer_ID":"C","selected":false,"answer_text":"Material"},
        {"answer_ID":"D","selected":false,"answer_text":"Cost center"},
    ],
    "answer_correct":["B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":136,
    "question_text":"Which of the following are captured in a completed Digital Discovery Assessment? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Target customer go-live date"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Fiori application extensions"},
        {"answer_ID":"C","selected":false,"answer_text":"Business role requirements"},
        {"answer_ID":"D","selected":false,"answer_text":"Known integration requirements"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":137,
    "question_text":"Which options are available for the bank account revision in SAP S/4HANA Cloud? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Activate automatically"},
        {"answer_ID":"B","selected":false,"answer_text":"Activate directly"},
        {"answer_ID":"C","selected":false,"answer_text":"Activate via dual control"},
        {"answer_ID":"D","selected":false,"answer_text":"Activate via machine learning"},
    ],
    "answer_correct": ["B","C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":138,
    "question_text":"Which function of SAP Financial Statement Insight utilizes machine learning? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Business exception detection"},
        {"answer_ID":"B","selected":false,"answer_text":"Flexible comparisons across multiple characteristics"},
        {"answer_ID":"C","selected":false,"answer_text":"Visualization of account group performance"},
        {"answer_ID":"D","selected":false,"answer_text":"Dynamic adaptation of hierarchical structures"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":139,
    "question_text":"Which prerequisites need to be fulfilled to reconcile cash flows from intraday bank statements? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Perform the required settings for importing bank statements"},
        {"answer_ID":"B","selected":false,"answer_text":"Set the reconciliation status open in the Cash Flow Analyzer"},
        {"answer_ID":"C","selected":false,"answer_text":"Activate intraday memo records at the bank account level"},
        {"answer_ID":"D","selected":false,"answer_text":"Define the tolerance groups for cash flow reconciliation"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":140,
    "question_text":"In SAP Central Business Configuration, which changes are possible for the Starter System after content activation? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Create additional product-specific configurations"},
        {"answer_ID":"B","selected":false,"answer_text":"Change the group ledger scenario"},
        {"answer_ID":"C","selected":false,"answer_text":"Add new organizational entities"},
        {"answer_ID":"D","selected":false,"answer_text":"Edit existing product-specific configurations"},
        {"answer_ID":"E","selected":false,"answer_text":"Delete existing organizational entities"}
    ],
    "answer_correct":["A", "B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":141,
    "question_text":"Where do you specify the retention time of temporary data in the SAP S/4HANA Migration Cockpit? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"In the staging tables"},
        {"answer_ID":"B","selected":false,"answer_text":"In the migration object template"},
        {"answer_ID":"C","selected":false,"answer_text":"In the migration project"},
        {"answer_ID":"D","selected":false,"answer_text":"In the migration object"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":142,
    "question_text":"Which process does the Content Lifecycle Management support? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Extend standard processes with customer specific steps"},
        {"answer_ID":"B","selected":false,"answer_text":"Activate new best practices processes without disruption"},
        {"answer_ID":"C","selected":false,"answer_text":"Migrate master records and balances from legacy systems"},
        {"answer_ID":"D","selected":false,"answer_text":"Integrate with other solutions with minimum configuration"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":143,
    "question_text":"What element identifies the business transaction for asset postings? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"The app used to post"},
        {"answer_ID":"B","selected":false,"answer_text":"The transaction type"},
        {"answer_ID":"C","selected":false,"answer_text":"The asset class"},
        {"answer_ID":"D","selected":false,"answer_text":"The document type"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":144,
    "question_text":"Which of the following objects must be available before legacy G/L account balances can be completely migrated in the SAP S/4HANA Cloud system? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Fixed asset"},
        {"answer_ID":"B","selected":false,"answer_text":"Internal order"},
        {"answer_ID":"C","selected":false,"answer_text":"Business partner"},
        {"answer_ID":"D","selected":false,"answer_text":"Business area"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":145,
    "question_text":"What can you use the View Browser app to do? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Create, edit, and delete CDS views"},
        {"answer_ID":"B","selected":false,"answer_text":"Display custom SAP view sir respective of draft or released status"},
        {"answer_ID":"C","selected":false,"answer_text":"Launch analytic cloud stories"},
        {"answer_ID":"D","selected":false,"answer_text":"Get a list of all available analytical CDS views and their artifacts"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":146,
    "question_text":"For which object do you maintain the activity price to perform activity allocation? - Choose one:" ,
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Cost center"},
        {"answer_ID":"B","selected":false,"answer_text":"Key figure"},
        {"answer_ID":"C","selected":false,"answer_text":"Profit center"},
        {"answer_ID":"D","selected":false,"answer_text":"Internal order"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":147,
    "question_text":"What are characteristics of the SAP S/4HANA Migration Cockpit? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Guidance and simulation of the migration process"},
        {"answer_ID":"B","selected":false,"answer_text":"Combining the staging and file approaches into one migration project"},
        {"answer_ID":"C","selected":false,"answer_text":"Mapping source values to SAP S/4HANA target values"},
        {"answer_ID":"D","selected":false,"answer_text":"Extensibility using the Legacy System Migration Workbench (LSMW)"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":148,
    "question_text":"When is the Quality system activated? (X) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"After SAP Best Practices content is migrated from the Starter system to the Quality system"},
        {"answer_ID":"B","selected":false,"answer_text":"After the organizational structure and chart of accounts are defined"},
        {"answer_ID":"C","selected":false,"answer_text":"After a new change project is created"},
        {"answer_ID":"D","selected":false,"answer_text":"After the Starter system is terminated"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":149,
    "question_text":"What hosts the Test Execution Service that drives automated business process testing in SAP S/4HANA Cloud? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Business Technology Platform"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Cloud Identity"},
        {"answer_ID":"C","selected":false,"answer_text":"Manage your Solution app"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP HANA Enterprise Cloud"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":150,
    "question_text":"Where do you activate SAP Best Practice processes in your quality system? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP S/4HANA Cloud Manage Your Solution app"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Best Practices Explorer"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Solution Builder"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Central Business Configuration"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":151,
    "question_text":"Which of the following security measures is the customer responsible for? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Deactivation of SAP administrative users"},
        {"answer_ID":"B","selected":false,"answer_text":"API management"},
        {"answer_ID":"C","selected":false,"answer_text":"Application-level user management"},
        {"answer_ID":"D","selected":false,"answer_text":"Change logging through audit trails"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":152,
    "question_text":"In which type of extensibility is the SAP Business Technology Platform used to host a custom extension? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Developer extensibility"},
        {"answer_ID":"B","selected":false,"answer_text":"Side-by-side extensibility"},
        {"answer_ID":"C","selected":false,"answer_text":"Classic extensibility"},
        {"answer_ID":"D","selected":false,"answer_text":"Key user extensibility"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":153,
    "question_text":"In general journal entries. Which date determines the reporting period in which document values are included? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Journal Entry Date"},
        {"answer_ID":"B","selected":false,"answer_text":"Reference Date"},
        {"answer_ID":"C","selected":false,"answer_text":"Posting Date"},
        {"answer_ID":"D","selected":false,"answer_text":"Translation Date"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":154,
    "question_text":"Which technology is used to perform predictive accounting for sales orders in SAP S/4HANA Cloud? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Extension ledger"},
        {"answer_ID":"B","selected":false,"answer_text":"Machine learning"},
        {"answer_ID":"C","selected":false,"answer_text":"Distributed ledger"},
        {"answer_ID":"D","selected":false,"answer_text":"Smart contracts"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":155,
    "question_text":"You have created a new bank account and saved it with status “Inactive”. What action can you perform on the account? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Revert to Active"},
        {"answer_ID":"B","selected":false,"answer_text":"Activate the Bank Account"},
        {"answer_ID":"C","selected":false,"answer_text":"Close the Bank Account"},
        {"answer_ID":"D","selected":false,"answer_text":"Delete the Bank Account"},
    ],
    "answer_correct":["B", "C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":156,
    "question_text":"Which types of datasets are required for predictive scenarios? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Apply Dataset"},
        {"answer_ID":"B","selected":false,"answer_text":"Active Dataset"},
        {"answer_ID":"C","selected":false,"answer_text":"Training Dataset"},
        {"answer_ID":"D","selected":false,"answer_text":"Testing Dataset"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":157,
    "question_text":"Which functionalities are supported by the SAP Fiori Test Your Processes app? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Import custom test plans"},
        {"answer_ID":"B","selected":false,"answer_text":"Remediate failed test scenarios"},
        {"answer_ID":"C","selected":false,"answer_text":"Create or change test plans"},
        {"answer_ID":"D","selected":false,"answer_text":"Migrate test plans for execution"},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":158,
    "question_text":"How can you migrate data to SAP S/4HANA Cloud, public edition? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"File templates"},
        {"answer_ID":"B","selected":false,"answer_text":"Core Data Services"},
        {"answer_ID":"C","selected":false,"answer_text":"Staging database"},
        {"answer_ID":"D","selected":false,"answer_text":"RFC calls"},
    ],
    "answer_correct":["A", "B" ],
    "score":0,
    "responded":false,
    "explenation":"I think it’s A&C, it’s just like it’s always been with the migration cockpit: File templates & Staging tables."
},
{
    "chapter_ID":1,
    "question_ID":159,
    "question_text":"What activities are applied to the entire system and cannot be changed after confirmation? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Group currency"},
        {"answer_ID":"B","selected":false,"answer_text":"Scoping"},
        {"answer_ID":"C","selected":false,"answer_text":"Configuration activities"},
        {"answer_ID":"D","selected":false,"answer_text":"Fiscal year variant"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":160,
    "question_text":"Which self-service configuration (SSC) task is required to calculate the bank account closing balance for Cash Pooling? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Assign planning levels to profiles"},
        {"answer_ID":"B","selected":false,"answer_text":"Define alternative planning levels for transactions"},
        {"answer_ID":"C","selected":false,"answer_text":"Assign planning levels to cash requests"},
        {"answer_ID":"D","selected":false,"answer_text":"Define daily transfer amount by cash pools"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":161,
    "question_text":"How is the technical clearing account used during integrated asset acquisitions? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"It is credited with the amount posted to the asset in the valuation documents."},
        {"answer_ID":"B","selected":false,"answer_text":"It is debited with the amount posted to the supplier in the operational document."},
        {"answer_ID":"C","selected":false,"answer_text":"It is debited with the amount posted to the supplier in the valuation documents."},
        {"answer_ID":"D","selected":false,"answer_text":"It is credited with the amount posted to the asset in the operational document."},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":162,
    "question_text":"When processing payments, what are possible functions of reason codes? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Generate a payment reminder" },
        {"answer_ID":"B","selected":false,"answer_text":"Automatic posting of a residual item to a specified G/L account"},
        {"answer_ID":"C","selected":false,"answer_text":"Control of the account where a residual item is posted"},
        {"answer_ID":"D","selected":false,"answer_text":"Control of the type of payment notice sent to a customer"},
    {"answer_ID":"E.","selected":false,"answer_text":"Create a sales order"}
    ],
    "answer_correct":["B", "C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":163,
    "question_text":"What do you use to collect the values that are entered into the configuration activities in SAP Central Business Configuration? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Cloud ALM Processes app"},
        {"answer_ID":"B","selected":false,"answer_text":"Availability and Dependencies of Solution Processes Excel"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Cloud ALM Requirements app"},
        {"answer_ID":"D","selected":false,"answer_text":"Business Driven Configuration Questionnaire"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":164,
    "question_text":"You define a segment in the Manage your Solution app. What must you assign it to? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"General ledger accounts"},
        {"answer_ID":"B","selected":false,"answer_text":"Profit centers"},
        {"answer_ID":"C","selected":false,"answer_text":"Ledgers"},
        {"answer_ID":"D","selected":false,"answer_text":"Cost centers"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":165,
    "question_text":"For an asset under construction (AuC) linked to a WBS element, what do you do? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Post depreciation for the AuC after costs are first debited to it."},
        {"answer_ID":"B","selected":false,"answer_text":"Maintain a settlement rule for the WBS element."},
        {"answer_ID":"C","selected":false,"answer_text":"Post cost to the AuC through settlement of the WBS element"},
        {"answer_ID":"D","selected":false,"answer_text":"Post costs directly to the AuC since the WBS element is always statistical."},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":166,
    "question_text":"What are key activities of the Prepare phase of the SAP Activate methodology? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Starter system provisioning"},
        {"answer_ID":"B","selected":false,"answer_text":"User training"},
        {"answer_ID":"C","selected":false,"answer_text":"Project setup"},
        {"answer_ID":"D","selected":false,"answer_text":"Data migration"},
    {"answer_ID":"E.","selected":false,"answer_text":"Customer self-enablement"}
    ],
    "answer_correct":["A", "C", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":167,
    "question_text":"Which steps are required for data load preparation? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Determine data requirements based on scenario reviews"},
        {"answer_ID":"B","selected":false,"answer_text":"Determine if public or private cloud is required for data migration"},
        {"answer_ID":"C","selected":false,"answer_text":"Review data load templates and determine data mapping"},
        {"answer_ID":"D","selected":false,"answer_text":"Perform full data load to determine the required loading times"},
    {"answer_ID":"E.","selected":false,"answer_text":"Design programs or reports required to extract data and initiate data cleansing"}
    ],
    "answer_correct":["C", "D", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":168,
    "question_text":"Which substitution types can you configure for journal entry substitution rules? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Substitute with formula value"},
        {"answer_ID":"B","selected":false,"answer_text":"Substitute with field value"},
        {"answer_ID":"C","selected":false,"answer_text":"Substitute with previous value"},
        {"answer_ID":"D","selected":false,"answer_text":"Substitute with constant value"},
    {"answer_ID":"E.","selected":false,"answer_text":"Clear field value"}
    ],
    "answer_correct":["B", "D", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":169,
    "question_text":"Which fields can you define on the document header of a general ledger posting? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Posting Date"},
        {"answer_ID":"B","selected":false,"answer_text":"G/L Account"},
        {"answer_ID":"C","selected":false,"answer_text":"Posting Key"},
        {"answer_ID":"D","selected":false,"answer_text":"Journal Entry Type"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":170,
    "question_text":"What must you create when you build a communication scenario in SAP S/4HANA Cloud? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Communication interface"},
        {"answer_ID":"B","selected":false,"answer_text":"Communication system"},
        {"answer_ID":"C","selected":false,"answer_text":"Communication user"},
        {"answer_ID":"D","selected":false,"answer_text":"Communication API"},
    {"answer_ID":"E.","selected":false,"answer_text":"Communication arrangement"}
    ],
    "answer_correct":["B", "C", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":171,
    "question_text":"Which of the following systems are used to implement SAP S/4HANA Cloud Public Edition for a customer? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Starter system"},
        {"answer_ID":"B","selected":false,"answer_text":"Sandbox system"},
        {"answer_ID":"C","selected":false,"answer_text":"Development system"},
        {"answer_ID":"D","selected":false,"answer_text":"Trial system"},
    {"answer_ID":"E.","selected":false,"answer_text":"Test system"}
    ],
    "answer_correct":["A", "C", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":172,
    "question_text":"Which of the following fields is maintained on the general data segment of the customer business partner? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Payment method supplement"},
        {"answer_ID":"B","selected":false,"answer_text":"Bank key"},
        {"answer_ID":"C","selected":false,"answer_text":"House bank"},
        {"answer_ID":"D","selected":false,"answer_text":"Lockbox"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":173,
    "question_text":"What test process types are available in the manage your Test Processes app? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Standard"},
        {"answer_ID":"B","selected":false,"answer_text":"Regression"},
        {"answer_ID":"C","selected":false,"answer_text":"Custom"},
        {"answer_ID":"D","selected":false,"answer_text":"Integration"},
    {"answer_ID":"E.","selected":false,"answer_text":"Post-upgrade"}
    ],
    "answer_correct":["B", "C", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":174,
    "question_text":"Which of the following are outcomes of the Fit-to-Standard workshop? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Identification of source code changes"},
        {"answer_ID":"B","selected":false,"answer_text":"Solution impact on business processes"},
        {"answer_ID":"C","selected":false,"answer_text":"Finalized scope and integration requirements"},
        {"answer_ID":"D","selected":false,"answer_text":"Extensibility solutions to close gaps"},
    {"answer_ID":"E.","selected":false,"answer_text":"Expert configuration definition"}
    ],
    "answer_correct":["B", "D", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":175,
    "question_text":"Who should the customer IT Contact provide with administrator authorization when creating users for the SAP S/4HANA Cloud starter system? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Project manager"},
        {"answer_ID":"B","selected":false,"answer_text":"Customer LoB experts"},
        {"answer_ID":"C","selected":false,"answer_text":"LoB configuration experts"},
        {"answer_ID":"D","selected":false,"answer_text":"Lead configuration expert"},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":176,
    "question_text":"According to the General Data Protection Regulation (GDPR) laws, when are SAP customers considered data controllers? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"When they extract legacy data in preparation for migration"},
        {"answer_ID":"B","selected":false,"answer_text":"When they engage other businesses in the European Union"},
        {"answer_ID":"C","selected":false,"answer_text":"When they request implementation of a cloud solution"},
        {"answer_ID":"D","selected":false,"answer_text":"When they use SAP cloud applications"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":177,
    "question_text":"Through which component do you access your assigned process tasks from the SAP Activate Roadmap of SAP S/4HANA Cloud Public Edition (3-system landscape)? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Central Business Configuration"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Cloud ALM for Operation"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Cloud ALM for Service"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Cloud ALM for Implementation"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":178,
    "question_text":"What is the role of the valuation method in the foreign currency valuation of accounts payable? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Define the valuation procedure"},
        {"answer_ID":"B","selected":false,"answer_text":"Define the document type for the valuation posting"},
        {"answer_ID":"C","selected":false,"answer_text":"Determine the G/L accounts for the valuation posting"},
        {"answer_ID":"D","selected":false,"answer_text":"Define the posting and reversal date for the valuation posting"},
    {"answer_ID":"E.","selected":false,"answer_text":"Define the exchange rate type"}
    ],
    "answer_correct":["A", "C", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":179,
    "question_text":"On which levels can posting to a general ledger account be blocked? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Chart of accounts"},
        {"answer_ID":"B","selected":false,"answer_text":"Company code"},
        {"answer_ID":"C","selected":false,"answer_text":"Segment"},
        {"answer_ID":"D","selected":false,"answer_text":"Ledger"},
    ],
    "answer_correct":["A", "B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":180,
    "question_text":"In the Manage Fixed Assets app, assets that are created in the database but not activated or deactivated are displayed under which tab? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Saved"},
        {"answer_ID":"B","selected":false,"answer_text":"Created"},
        {"answer_ID":"C","selected":false,"answer_text":"Acquired"},
        {"answer_ID":"D","selected":false,"answer_text":"Draft"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":181,
    "question_text":"Which technology is used to integrate SAP S/4HANA Cloud with other SAP and non-SAP applications? (X) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SQL Connect function"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Change and Transport System"},
        {"answer_ID":"C","selected":false,"answer_text":"Direct connectivity"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Landscape Transformation Replication Server"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":182,
    "question_text":"For which of the following activities is the started system used? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Run integration testing."},
        {"answer_ID":"B","selected":false,"answer_text":"Document delta requirements in the backlog."},
        {"answer_ID":"C","selected":false,"answer_text":"Transport the solution configuration to the quality system."},
        {"answer_ID":"D","selected":false,"answer_text":"Set up the Test Automation Tool."},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":183,
    "question_text":"What middleware is used to integrate SAP S/4HANA Cloud with SAP Concur? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Process Integration"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Process Orchestration"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Landscape Transformation Replication Server"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Cloud Platform Integration"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":184,
    "question_text":"What defines the organizational entities that are required in SAP Central Business Configuration? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Added partner content"},
        {"answer_ID":"B","selected":false,"answer_text":"The selected scope"},
        {"answer_ID":"C","selected":false,"answer_text":"System consistency checks"},
        {"answer_ID":"D","selected":false,"answer_text":"The selected deployment target"},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":185,
    "question_text":"If the headquarter organization runs a line of business spin-off in SAP S/4HANA Cloud Public Edition, which two-tier ERP scenario is being used? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Central Services"},
        {"answer_ID":"B","selected":false,"answer_text":"Selective Data Transition"},
        {"answer_ID":"C","selected":false,"answer_text":"Headquarter and Subsidiary"},
        {"answer_ID":"D","selected":false,"answer_text":"Supply Chain Ecosystem"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":186,
    "question_text":"If a customer wants to develop their own custom application and integrate it with SAP S/4HANA Cloud Public Edition, what tools would you recommend to develop the app? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Cloud Portal ServiceRF"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Build"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Business Application Studio"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP HANA Cloud"},
    ],
    "answer_correct":["C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":187,
    "question_text":"Which fields can you define on the Chart of Accounts level of a G/L Account? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Account group"},
        {"answer_ID":"B","selected":false,"answer_text":"G/L account type"},
        {"answer_ID":"C","selected":false,"answer_text":"Functional area"},
        {"answer_ID":"D","selected":false,"answer_text":"Account currency"},
    ],
    "answer_correct":["A", "B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":188,
    "question_text":"What can you do when executing a depreciation posting run? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"You can process a depreciation test run for individual assets"},
        {"answer_ID":"B","selected":false,"answer_text":"You can check the posted depreciation for each asset on the FI document"},
        {"answer_ID":"C","selected":false,"answer_text":"You can run depreciation separately for each ledger"},
        {"answer_ID":"D","selected":false,"answer_text":"You can reverse the FI posting documents of the depreciation run"},
    {"answer_ID":"E.","selected":false,"answer_text":"You can select to cancel the depreciation run if the program finds errors in individual assets"}
    ],
    "answer_correct":["B", "C", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":189,
    "question_text":"Which of the following changes would prevent SAP from running automated business process tests on your behalf? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Configuration changes that impact the process flow"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP-made changes to the standard test process"},
        {"answer_ID":"C","selected":false,"answer_text":"Changes to the data input requirements (custom data fields)"},
        {"answer_ID":"D","selected":false,"answer_text":"Changes to the SAP S/4HANA Cloud release (upgrade)"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":190,
    "question_text":"What does the journal entry type in general journal document posting control? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Which fields in the line items are mandatory fields."},
        {"answer_ID":"B","selected":false,"answer_text":"Which fields in the document header are mandatory fields."},
        {"answer_ID":"C","selected":false,"answer_text":"The document number assigned"},
        {"answer_ID":"D","selected":false,"answer_text":"The number of possible line items in the Fl document."},
    ],
    "answer_correct":["A", "B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":191,
    "question_text":"Which of the processes below are mandatory when processing a payment run? (X – certif) - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Maintain Parameters"},
        {"answer_ID":"B","selected":false,"answer_text":"Schedule Proposal Run"},
        {"answer_ID":"C","selected":false,"answer_text":"Schedule Payment Run"},
        {"answer_ID":"D","selected":false,"answer_text":"Upload Bank Statement"},
    {"answer_ID":"E.","selected":false,"answer_text":"Schedule Invoice Run"}
    ],
    "answer_correct":["A", "B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":192,
    "question_text":"What are the available methods through which you can fill staging tables in the SAP HANA database of the target system? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP SQL Anywhere"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Smart Data Access"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP HANA Smart Data Integration (SDI)"},
        {"answer_ID":"D","selected":false,"answer_text":"Customer’s ETL tools"},
    {"answer_ID":"E.","selected":false,"answer_text":"XML templates"}
    ],
    "answer_correct":["C", "D", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":193,
    "question_text":"Which feature is available with a resubmission in Collections & Dispute Management? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"It provides a specific reason why a customer needs to be contacted again"},
        {"answer_ID":"B","selected":false,"answer_text":"It initiates the dispute resolution process for one or several invoices"},
        {"answer_ID":"C","selected":false,"answer_text":"It documents the details of a customer’s pledge to pay an invoice"},
        {"answer_ID":"D","selected":false,"answer_text":"It transfers the dispute case to the collections worklist"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":194,
    "question_text":"Two partners agree on a financial transaction with a repayment at the end of the contract with no interest payments made during the term. Which financial transaction is used? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Cash flow transaction"},
        {"answer_ID":"B","selected":false,"answer_text":"Deposit at notice"},
        {"answer_ID":"C","selected":false,"answer_text":"Bilateral facility"},
        {"answer_ID":"D","selected":false,"answer_text":"Commercial paper"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":195,
    "question_text":"What are the 3 key components of the Intelligent Enterprise SAP offering? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Digital Platform"},
        {"answer_ID":"B","selected":false,"answer_text":"Intelligent Technologies"},
        {"answer_ID":"C","selected":false,"answer_text":"Intelligent Data Analytics"},
        {"answer_ID":"D","selected":false,"answer_text":"Intelligent Suite"},
    {"answer_ID":"E.","selected":false,"answer_text":"Intelligent Enterprise Architecture"}
    ],
    "answer_correct":["A", "B", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":196,
    "question_text":"Which application provides the electronic bill presentment capability and the payment capability? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Cash Application"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Cloud Platform the digital payments"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Cloud Platform for customer payments"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Real Spend"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":197,
    "question_text":"Which application do you use to enter activity prices to allocate costs from cost centers to production orders? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Maintain Activity Cost Rates (CMAC02)"},
        {"answer_ID":"B","selected":false,"answer_text":"Manage Cost Rates - Professional Services"},
        {"answer_ID":"C","selected":false,"answer_text":"Manage Cost Rates - Actual"},
        {"answer_ID":"D","selected":false,"answer_text":"Manage Cost Rates – Plan"},
    ],
    "answer_correct":["A"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":198,
    "question_text":"You have a bank account in foreign currency. During the period you have posted various deposits to and withdrawals from the account. After the foreign currency valuation run, how does the system evaluate the balance of this account? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"In local currency, with the exchange rate valid for each transaction"},
        {"answer_ID":"B","selected":false,"answer_text":"In foreign currency, converted to local currency on a statistical ledger."},
        {"answer_ID":"C","selected":false,"answer_text":"In foreign currency, with no need for conversion."},
        {"answer_ID":"D","selected":false,"answer_text":"In local currency, with the exchange rate valid on the last day of the period"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":199,
    "question_text":"Which of the following consumer applications are integrated out-of-the-box with the payment card process of SAP digital payments add-on? - Choose three:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Contract Accounting"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP S/4HANA Cloud for Customer Payments"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Real Spend"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Cash Application"},
    {"answer_ID":"E.","selected":false,"answer_text":"External Billing"}
    ],
    "answer_correct":["A","B", "E"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":200,
    "question_text":"Your customer wants to simplify the process of outgoing payment management. Which offering do you recommend? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Real Spend"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Cash Application"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP Multi-Bank Connectivity"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Bank Analyzer"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":201,
    "question_text":"Which organizational units are directly linked to a plant? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Credit control area"},
        {"answer_ID":"B","selected":false,"answer_text":"Company code"},
        {"answer_ID":"C","selected":false,"answer_text":"Storage location"},
        {"answer_ID":"D","selected":false,"answer_text":"Sales office"},
    ],
    "answer_correct":["B", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":202,
    "question_text":"Which market data categories are available for manual upload in the app 'Import Market Data'? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"FX spot rates"},
        {"answer_ID":"B","selected":false,"answer_text":"Commodities"},
        {"answer_ID":"C","selected":false,"answer_text":"Index values"},
        {"answer_ID":"D","selected":false,"answer_text":"Credit spreads"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":203,
    "question_text":"What capabilities are available in SAP Central Business Configuration? (X) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Provision the quality and production system"},
        {"answer_ID":"B","selected":false,"answer_text":"Migrate data"},
        {"answer_ID":"C","selected":false,"answer_text":"Deploy the solution scope"},
        {"answer_ID":"D","selected":false,"answer_text":"Maintain Business Users"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":204,
    "question_text":"Which of the following postings update the ACDOCA universal journal table? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Commitment values from purchase order"},
        {"answer_ID":"B","selected":false,"answer_text":"Enter statistical key figure values"},
        {"answer_ID":"C","selected":false,"answer_text":"Plan cost center allocation"},
        {"answer_ID":"D","selected":false,"answer_text":"Posting to statistical project"},
    ],
    "answer_correct":["A", "B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":205,
    "question_text":"You are performing data migration using the SAP S/4HANA Migration Cockpit. The same record exists twice in your upload file. At which stage does the upload of the migration template fail due to the duplicated record? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Convert values"},
        {"answer_ID":"B","selected":false,"answer_text":"Execute Import"},
        {"answer_ID":"C","selected":false,"answer_text":"Validate Data"},
        {"answer_ID":"D","selected":false,"answer_text":"Simulate Import"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":206,
    "question_text":"In SAP Central Business Configuration, what does the - Confirm Scope and Organizational Structure is Completed milestone trigger in the Implementation Project? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Installation of the Starter System"},
        {"answer_ID":"B","selected":false,"answer_text":"Installation of the Production System"},
        {"answer_ID":"C","selected":false,"answer_text":"Initial Transport from Quality to Production"},
        {"answer_ID":"D","selected":false,"answer_text":"Installation of the Quality System"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":207,
    "question_text":"Where is the initial list of known integrations documented? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"In the Fit-to-Standard Workshop"},
        {"answer_ID":"B","selected":false,"answer_text":"In the Business-Driven Configuration Questionnaire"},
        {"answer_ID":"C","selected":false,"answer_text":"In the Digital Discovery Assessment"},
        {"answer_ID":"D","selected":false,"answer_text":"In SAP Cloud ALM"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":208,
    "question_text":"For which objects can you perform budget availability control? - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Cost Center"},
        {"answer_ID":"B","selected":false,"answer_text":"Profit Center"},
        {"answer_ID":"C","selected":false,"answer_text":"Project"},
        {"answer_ID":"D","selected":false,"answer_text":"Internal Order"},
    ],
    "answer_correct":["A", "D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":209,
    "question_text":"Which dimension do you use to produce external financial statements based on cost-of-sales accounting? - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Market segment"},
        {"answer_ID":"B","selected":false,"answer_text":"Profit center"},
        {"answer_ID":"C","selected":false,"answer_text":"Segment"},
        {"answer_ID":"D","selected":false,"answer_text":"Functional area"},
    ],
    "answer_correct":["D"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":210,
    "question_text":"Which process in the integrated procure-to-pay process generates a balance sheet relevant financial accounting document? (X – certif) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Goods receipt"},
        {"answer_ID":"B","selected":false,"answer_text":"Material requirements planning"},
        {"answer_ID":"C","selected":false,"answer_text":"Invoice verification"},
        {"answer_ID":"D","selected":false,"answer_text":"Purchase order creation"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":211,
    "question_text":"What provides a foundation for the SAP Cloud ERP where integrations and extensions live? (X – certif) (X) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"SAP Discovery Center"},
        {"answer_ID":"B","selected":false,"answer_text":"SAP Business Technology Platform"},
        {"answer_ID":"C","selected":false,"answer_text":"SAP ABAP Environment"},
        {"answer_ID":"D","selected":false,"answer_text":"SAP Business Accelerator Hub"},
    ],
    "answer_correct":["B"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":212,
    "question_text":"On what level can you restrict postings using the posting period variant? (X – certif) (X) - Choose two:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"Customer reconciliation account"},
        {"answer_ID":"B","selected":false,"answer_text":"Fixed asset number"},
        {"answer_ID":"C","selected":false,"answer_text":"G/L account"},
        {"answer_ID":"D","selected":false,"answer_text":"Supplier account"},
    ],
    "answer_correct":["A", "C"],
    "score":0,
    "responded":false,
    "explenation":""
},
{
    "chapter_ID":1,
    "question_ID":213,
    "question_text":"When do you specify a data retention period in the SAP S/4HANA Migration Cockpit? (X – certif) - Choose one:",
    "answer_options":[
        {"answer_ID":"A","selected":false,"answer_text":"When project status is “Created”"},
        {"answer_ID":"B","selected":false,"answer_text":"When project status is “In Progress”"},
        {"answer_ID":"C","selected":false,"answer_text":"When project status is “Finished”"},
        {"answer_ID":"D","selected":false,"answer_text":"When project status is “Completed”"},
    ],
    "answer_correct":["C"],
    "score":0,
    "responded":false,
    "explenation":""
}
]