import React,{useState}from 'react'
import { SAP_FI } from "../../Data/Questions/SAP_FI";

import QuestionCard from './component/QuestionCard'

function QuestionOverview() {
    const [currentPage, setCurrentPage] = useState(1);
    const [questionsPerPage] = useState(10);
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;

    const currentQuestions = SAP_FI.slice(
      indexOfFirstQuestion,
      indexOfLastQuestion
    );
    
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(SAP_FI.length / questionsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    const handlePageClick = (event) => {
      setCurrentPage(Number(event.target.id));
      window.scrollTo({
        top:0,
        behavior: 'smooth'
      })
    };

    return (
        <>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden my-5 w-4/5 mx-auto">
                <div>
                    {currentQuestions.map((question,index)=>(
                        <QuestionCard key={index} question={question}/>
                    ))}
                </div>
                <div className="flex items-center justify-center">
                    {pageNumbers.map((number) => (
                    <button
                        key={number}
                        id={number}
                        onClick={handlePageClick}
                        className={`bg-gray-200 text-gray-700 font-semibold mx-1 px-4 py-2 my-3 rounded-md ${
                        currentPage === number ? "bg-gray-400" : ""
                        }`}
                    >
                        {number}
                    </button>
                    ))}
                </div>
            </div>
        </>
    );
}

export default QuestionOverview;