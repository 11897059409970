import React,{useState,useEffect}from 'react'
import { useLocation } from 'react-router-dom';
import { results } from '../Data/results';

function Results() {




    return (
        <>
            <div className='flex flex-col h-full'>
                <div className="flex flex-col items-center justify-center h-40 bg-gray-100">
                    <h1 className="text-gray-800 font-bold text-4xl mb-2">Results</h1>
                </div>
                <div className="flex flex-col items-center justify-center h-3/5 p-5">
                    <div className="bg-gray-200 p-4 rounded-lg w-2/3">
                        {results.map((res, index) => (
                            <div className="bg-white shadow-lg rounded-lg my-4 mx-auto">
                                <h2 className="text-gray-800 font-bold text-2xl mb-2">User: {res.user_id} - quiz: {res.quiz_id} - Results: {res.questions}/{res.result}</h2>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Results;